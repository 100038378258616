











































import { Component, Vue } from 'nuxt-property-decorator'

@Component<MCustomersLogos>({})
export default class MCustomersLogos extends Vue {
  logos = [
    {
      name: 'Ford',
      image: require('@/assets/img/customers/logos/Ford.png')
    },
    {
      name: 'BMW',
      image: require('@/assets/img/customers/logos/BMW.png')
    },
    // {
    //   name: 'RKS Raków Częstochowa',
    //   image: require('@/assets/img/customers/logos/RKS Raków Częstochowa.png')
    // },
    // {
    //   name: 'x-kom',
    //   image: require('@/assets/img/customers/logos/x-kom.png')
    // },
    {
      name: 'WGN',
      image: require('@/assets/img/customers/logos/WGN.png')
    },
    {
      name: 'Fabryka Rowerów',
      image: require('@/assets/img/customers/logos/Fabryka Rowerów.png')
    },
    {
      name: 'Budpol',
      image: require('@/assets/img/customers/logos/Budpol.png')
    },
    {
      name: 'StageArt',
      image: require('@/assets/img/customers/logos/StageArt.png')
    },
    {
      name: 'Everlasting Brows',
      image: require('@/assets/img/customers/logos/Everlasting Brows.png')
    },
    {
      name: 'Arsanit',
      image: require('@/assets/img/customers/logos/Arsanit.png')
    },
    {
      name: 'Topollino',
      image: require('@/assets/img/customers/logos/Topollino.png')
    },
    {
      name: 'Fructos',
      image: require('@/assets/img/customers/logos/Fructos.png')
    },
    {
      name: 'Petrana',
      image: require('@/assets/img/customers/logos/Petrana.png')
    },
    // {
    //   name: 'Ipsum Technics',
    //   image: require('@/assets/img/customers/logos/Ipsum Technics.png')
    // },
    {
      name: 'AZS',
      image: require('@/assets/img/customers/logos/AZS.png')
    },
    {
      name: 'ZNP',
      image: require('@/assets/img/customers/logos/ZNP.png')
    },
    {
      name: 'PSP',
      image: require('@/assets/img/customers/logos/PSP.png')
    },
    // {
    //   name: 'OSP',
    //   image: require('@/assets/img/customers/logos/OSP.png')
    // },
    // {
    //   name: 'ZZS Florian',
    //   image: require('@/assets/img/customers/logos/ZZS Florian.png')
    // }
  ]
}
