











import { Component, Vue } from 'vue-property-decorator';

@Component<AClutch>({})
export default class AClutch extends Vue {
  isMounted = false

  refDomain = ''

  get refPath() {
    return encodeURIComponent(this.$route.path)
  }

  mounted() {
    this.refDomain = encodeURIComponent(window.location.host)

    this.$nextTick(() => {
      this.isMounted = true
    })
  }
}
