















import { Component, Vue } from 'nuxt-property-decorator'

@Component<OCustomers>({})
export default class OCustomers extends Vue {}
