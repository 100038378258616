












import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<ACustomersLogoItem>({})
export default class ACustomersLogoItem extends Vue {
  @Prop({
    default: () => ({
      name: 'Logo',
      image: null
    })
  }) logo!: {
    name: string;
    image: string;
  }
}
