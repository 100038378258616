import { render, staticRenderFns } from "./PageCustomers.vue?vue&type=template&id=5cd877c7&lang=pug&"
import script from "./PageCustomers.vue?vue&type=script&lang=ts&"
export * from "./PageCustomers.vue?vue&type=script&lang=ts&"
import style0 from "./PageCustomers.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppClutch: require('/var/www/imoli.app/components/App/AppClutch.vue').default,PageCustomersLogos: require('/var/www/imoli.app/components/Page/PageCustomers/PageCustomersLogos.vue').default})
